import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  TermsModal,
  FieldSelect2, FieldCreatableSelect2
} from '../../../components';

import { TEACHER_ROLE, userRoles } from '../../../util/types';

import css from './SignupForm.module.css';
import config from "../../../config";

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        onOpenDiscountTermsOfService,
        selectedUserRole,
        initialEmail,
        fromOfferPage,
        values,
        stateOptions,
        userRoleOptions,
        areasCertifiedOptions,
        isTeacherSignUp,
        role,
      } = fieldRenderProps;

      // Discount Code
      const customDiscountCode = config.custom.discountCode;
      const discountCodeValid = validators.discountCodeValid('Discount code is invalid');

      const roleClass = role === "teacher" ? css.teacher : role === "parent" ? css.parent : css.admin

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // nickName
      const nicknameLabel = intl.formatMessage({id: 'SignupForm.nicknameLabel'});
      const nicknamePlaceholder = intl.formatMessage({id: 'SignupForm.nicknamePlaceholder'});
      const nicknameRequiredMessage = intl.formatMessage({id: 'SignupForm.nicknameRequired'});
      const nicknameRequired = validators.required(nicknameRequiredMessage);

      // userRole
      const userRoleLabel = intl.formatMessage({id: 'SignupForm.rolesLabel'});
      const userRolePlaceholder = intl.formatMessage({id: 'SignupForm.rolesPlaceholder'});
      const userRoleRequiredMessage = intl.formatMessage({id: 'SignupForm.roleRequiredMessage'});
      const isTeacher = values?.userRole?.key === 'teacher';

      // USA States
      const stateLabel = intl.formatMessage({ id: 'ProfileSettingsForm.stateLabel' });
      const statePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.statePlaceholder' });

      // areasCertified
      const areasCertifiedLabel = intl.formatMessage({ id: 'ProfileSettingsForm.areasCertifiedLabel' });
      const areasCertifiedPlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.areasCertifiedPlaceholder' });
      const sortedAreasCertifiedOptions = areasCertifiedOptions.sort(function (a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });


      const userRoleRequired = validators.required(userRoleRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const handleDiscountTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenDiscountTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      // const discountTermsLink = (
      //   <span
      //     className={css.termsLink}
      //     onClick={onOpenDiscountTermsOfService}
      //     role="button"
      //     tabIndex="0"
      //     onKeyUp={handleDiscountTermsKeyUp}
      //   >
      //     <FormattedMessage id="SignupForm.discountTermsAndConditionsLinkText" />
      //   </span>
      // );
      const discountLabel = values && values?.discountCode ? (<>
        <span>Discount code - </span>
        <span>
          <TermsModal/>
        </span>
      </>) : ( <span>Discount code </span>);
      // const userRoleDefaultValue = !!selectedUserRole && selectedUserRole === "teacher" ? userRoles[0].key :
      // !!selectedUserRole && selectedUserRole === "schoolAdministrator" ? userRoles[1].key : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {/*<FieldSelect2*/}
            {/*  id={formId ? `${formId}.userRole` : 'userRole'}*/}
            {/*  className={css.signUpSelect}*/}
            {/*  name="userRole"*/}
            {/*  label={userRoleLabel}*/}
            {/*  validate={validators.composeValidators(userRoleRequired)}*/}
            {/*  defaultValue={userRoleDefaultValue}*/}
            {/*  placeholder={userRolePlaceholder}*/}
            {/*  options={userRoleOptions}*/}
            {/*  isSearchable={true}*/}
            {/*  isClearable={false}*/}
            {/*  isMulti={false}*/}
            {/*  showSearchIcon={false}*/}
            {/*/>*/}

            {/*{isTeacher && <>*/}
            {/*  <FieldSelect2*/}
            {/*    name='usState'*/}
            {/*    id={formId ? `${formId}.usState` : 'usState'}*/}
            {/*    className={classNames(css.requiredLabel, css.item)}*/}
            {/*    label={stateLabel}*/}
            {/*    placeholder={statePlaceholder}*/}
            {/*    value={'selectedOption'}*/}
            {/*    isSearchable={true}*/}
            {/*    isClearable={false}*/}
            {/*    isMulti={false}*/}
            {/*    showSearchIcon={false}*/}
            {/*    options={stateOptions}*/}
            {/*    validate={validators.required('This field is required')}*/}
            {/*  />*/}

            {/*  <FieldCreatableSelect2*/}
            {/*    id={formId ? `${formId}.subjects` : 'subjects'}*/}
            {/*    name="subjects"*/}
            {/*    className={classNames(css.requiredLabel, css.item)}*/}
            {/*    label={areasCertifiedLabel}*/}
            {/*    placeholder={areasCertifiedPlaceholder}*/}
            {/*    isSearchable={true}*/}
            {/*    isClearable={true}*/}
            {/*    isMulti={true}*/}
            {/*    showSearchIcon={false}*/}
            {/*    showMultiValues={true}*/}
            {/*    options={sortedAreasCertifiedOptions}*/}
            {/*    validate={validators.requiredFieldArrayCheckbox('This field is required')}*/}
            {/*  />*/}
            {/*</>}*/}

            <FieldTextInput
              className={css.item}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              initialValue={initialEmail}
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            {/*<FieldTextInput*/}
            {/*  className={css.item}*/}
            {/*  type="text"*/}
            {/*  id={formId ? `${formId}.nname` : 'nname'}*/}
            {/*  name="nickname"*/}
            {/*  autoComplete="off"*/}
            {/*  label={nicknameLabel}*/}
            {/*  placeholder={nicknamePlaceholder}*/}
            {/*  validate={nicknameRequired}*/}
            {/*/>*/}

            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            {!isTeacherSignUp && (
              <FieldTextInput
                className={values && values?.discountCode ? classNames(css.item) : classNames(css.item, css.optionalLabel)}
                disabled={!!fromOfferPage}
                type="text"
                id={'discountCode'}
                name="discountCode"
                label={discountLabel}
                placeholder={'Discount code (optional)'}
                initialValue={fromOfferPage ? customDiscountCode : ''}
                validate={discountCodeValid}
              />
            )}
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <Button className={roleClass} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
