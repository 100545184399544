import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import {
  CheckMarkIcon,
} from '../../components';

import css from './AuthenticationPage.module.css';

export const ContentText = ({ role, content }) => {
  const isTeacher = role === 'teacher';
  const isParent = role === 'parent';
  const isAdmin = role === 'schoolAdministrator';
  const {
    slug,
    metaTitle,
    metaImage,
    metaDescription,
    mainDescription,
    internalName,
    ...items
  } = content;

  return (
    <div className={css.contentText}>
      {content ? (
        <>
          <h2 className={css.contentTextTitle}>
            {isTeacher ? <FormattedMessage
              id='AuthenticationPage.contentTextTitle'
              values={{ pinkText: <span className={css.roleText}><br />teaching jobs</span> }}
            /> : <FormattedMessage
              id='AuthenticationPage.contentTextTitle'
              values={{ pinkText: <span className={css.roleText}><br />teachers</span> }}
            />}
          </h2>

          <p className={css.contentTextDescription}>
            {content?.mainDescription}
          </p>

          <div className={css.contentTextDivider} />

          <ul className={css.contentTextList}>
            {Array.from(Array(Object.keys(items).length / 2).keys()).map(i => (
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    {items[`bullet${i + 1}Title`]}
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    {items[`bullet${i + 1}Description`]}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          {isTeacher && <>
            <p className={css.contentTextDescription}>
              <FormattedMessage id="AuthenticationPage.contentTextDescriptionT" />
            </p>

            <div className={css.contentTextDivider} />

            <ul className={css.contentTextList}>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle1T" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription1T" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle2T" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription2T" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle3T" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription3T" />
                  </p>
                </div>
              </li>
            </ul>
          </>}

          {isAdmin && <>
            <p className={css.contentTextDescription}>
              <FormattedMessage id="AuthenticationPage.contentTextDescriptionA" />
            </p>

            <div className={css.contentTextDivider} />

            <ul className={css.contentTextList}>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle1A" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription1A" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle2A" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription2A" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle3A" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription3A" />
                  </p>
                </div>
              </li>
            </ul>
          </>}

          {isParent && <>
            <p className={css.contentTextDescription}>
              <FormattedMessage id="AuthenticationPage.contentTextDescriptionP" />
            </p>

            <div className={css.contentTextDivider} />

            <ul className={css.contentTextList}>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle1P" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription1P" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle2P" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription2P" />
                  </p>
                </div>
              </li>
              <li className={css.contentTextListItem}>
                <CheckMarkIcon />
                <div>
                  <h3 className={css.contentTextListItemTitle}>
                    <FormattedMessage id="AuthenticationPage.contentListTitle3P" />
                  </h3>
                  <p className={css.contentTextListItemDescription}>
                    <FormattedMessage id="AuthenticationPage.contentListDescription3P" />
                  </p>
                </div>
              </li>
            </ul>
          </>}
        </>
      )}
    </div>
  );
};
