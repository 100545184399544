import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  TermsModal,
  FieldSelect2, FieldCreatableSelect2
} from '../../../components';

import { userRoles } from '../../../util/types';

import css from './ConfirmSignupForm.module.css';
import config from "../../../config";

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        authInfo,
        idp,
        fromOfferPage,
        values,
        stateOptions,
        userRoleOptions,
        areasCertifiedOptions,
        isTeacherSignUp,
      } = formRenderProps;

      // Discount Code
      const customDiscountCode = config.custom.discountCode;
      const discountCodeValid = validators.discountCodeValid('Discount code is invalid');
      const { role } = props;

      // email
      const emailLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // nickName
      const nicknameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.nicknameLabel',
      });
      const nicknamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.nicknamePlaceholder',
      });
      const nicknameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.nicknameRequired',
      });
      const nicknameRequired = validators.required(nicknameRequiredMessage);

      // userRole
      const userRoleLabel = intl.formatMessage({id: 'ConfirmSignupForm.rolesLabel'});
      const userRolePlaceholder = intl.formatMessage({id: 'ConfirmSignupForm.rolesPlaceholder'});
      const userRoleRequiredMessage = intl.formatMessage({id: 'ConfirmSignupForm.roleRequiredMessage'});
      const userRoleRequired = validators.required(userRoleRequiredMessage);
      const isTeacher = values?.userRole?.key === 'teacher';


      // USA States
      const stateLabel = intl.formatMessage({ id: 'ProfileSettingsForm.stateLabel' });
      const statePlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.statePlaceholder' });

      // areasCertified
      const areasCertifiedLabel = intl.formatMessage({ id: 'ProfileSettingsForm.areasCertifiedLabel' });
      const areasCertifiedPlaceholder = intl.formatMessage({ id: 'ProfileSettingsForm.areasCertifiedPlaceholder' });
      const sortedAreasCertifiedOptions = areasCertifiedOptions.sort(function (a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
        </span>
      );

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName } = authInfo;
      const roleClass = role === "teacher" ? css.teacher : role === "parent" ? css.parent : css.admin

      const discountLabel = values && values?.discountCode ? (<>
        <span>Discount code - </span>
        <span>
              <TermsModal/>
            </span>
      </>) : ( <span>Discount code </span>);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {/*<FieldSelect2*/}
            {/*  id={formId ? `${formId}.userRole` : 'userRole'}*/}
            {/*  className={css.signUpSelect}*/}
            {/*  name="userRole"*/}
            {/*  label={userRoleLabel}*/}
            {/*  validate={validators.composeValidators(userRoleRequired)}*/}
            {/*  placeholder={userRolePlaceholder}*/}
            {/*  options={userRoleOptions}*/}
            {/*  isSearchable={true}*/}
            {/*  isClearable={false}*/}
            {/*  isMulti={false}*/}
            {/*  showSearchIcon={false}*/}
            {/*/>*/}

            {/*{isTeacher && <>*/}
            {/*  <FieldSelect2*/}
            {/*    name='usState'*/}
            {/*    id={formId ? `${formId}.usState` : 'usState'}*/}
            {/*    className={classNames(css.requiredLabel, css.item)}*/}
            {/*    label={stateLabel}*/}
            {/*    placeholder={statePlaceholder}*/}
            {/*    value={'selectedOption'}*/}
            {/*    isSearchable={true}*/}
            {/*    isClearable={false}*/}
            {/*    isMulti={false}*/}
            {/*    showSearchIcon={false}*/}
            {/*    options={stateOptions}*/}
            {/*    validate={validators.required('This field is required')}*/}
            {/*  />*/}

            {/*  <FieldCreatableSelect2*/}
            {/*    id={formId ? `${formId}.subjects` : 'subjects'}*/}
            {/*    name="subjects"*/}
            {/*    className={classNames(css.requiredLabel, css.item)}*/}
            {/*    label={areasCertifiedLabel}*/}
            {/*    placeholder={areasCertifiedPlaceholder}*/}
            {/*    isSearchable={true}*/}
            {/*    isClearable={true}*/}
            {/*    isMulti={true}*/}
            {/*    showSearchIcon={false}*/}
            {/*    showMultiValues={true}*/}
            {/*    options={sortedAreasCertifiedOptions}*/}
            {/*    validate={validators.requiredFieldArrayCheckbox('This field is required')}*/}
            {/*  />*/}
            {/*</>}*/}



            <FieldTextInput
              className={css.item}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              initialValue={email}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            {/*<FieldTextInput*/}
            {/*  className={css.item}*/}
            {/*  type="text"*/}
            {/*  id={formId ? `${formId}.nname` : 'nname'}*/}
            {/*  name="nickname"*/}
            {/*  autoComplete="off"*/}
            {/*  label={nicknameLabel}*/}
            {/*  placeholder={nicknamePlaceholder}*/}
            {/*  validate={nicknameRequired}*/}
            {/*/>*/}
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
            </div>
          </div>

          {!isTeacherSignUp && (
            <FieldTextInput
              className={values && values?.discountCode ? classNames(css.item) : classNames(css.item, css.optionalLabel)}
              disabled={!!fromOfferPage}
              type="text"
              id={'discountCode'}
              name="discountCode"
              label={discountLabel}
              placeholder={'Discount code (optional)'}
              initialValue={fromOfferPage ? customDiscountCode : ''}
              validate={discountCodeValid}
            />
          )}

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="ConfirmSignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} className={roleClass} disabled={submitDisabled}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
